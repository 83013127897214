<template>
    <v-container fluid>
        <v-row justify-center
               align-center
               no-gutters
        >
            <v-col>
                <div class="content">
                    <v-col>
                        <fragment>
                            <div class="listing-container">
                                <div class="sticky-nav">
                                    <v-row
                                        class="col-md-12 no-gutters pt-0 pb-3 pl-3 pr-3 d-flex"
                                    >
                                        <div class="title-container">
                                            <slot name="title" />
                                        </div>
                                        <v-col cols="5"
                                               class="above-else d-flex pt-8"
                                        >
                                            <h1 class="page-header ml-4 ">
                                                Statistics
                                            </h1>
                                            <standalone-stats-date-picker
                                                v-model="headerState.filters.dateRange"
                                            />
                                            <library-filter
                                                v-model="headerState.filters.libraryIds"
                                            />
                                            <v-tooltip top
                                                       :disabled="!disableDownloadButton"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-on="on">
                                                        <v-btn :disabled="disableDownloadButton"
                                                               small
                                                               v-bind="attrs"
                                                               class="ml-2 v-btn download-button"
                                                               elevation="0"
                                                               :outlined="true"
                                                               :loading="showCsvProgress"
                                                               style=""
                                                               @click="downloadCsv()"
                                                               v-on="on"
                                                        >
                                                            Download CSV
                                                        </v-btn>
                                                    </div>
                                                </template>
                                                <span>Please select a library to enable the download</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-col class="pb-0 pt-0 sticky-table-header">
                                    <div
                                        class="col-md-12 pa-0 d-flex listing-header listing-header-container"
                                    >
                                        <div class="flex-grow-1 stats-title-column pt-2 pr-0 pl-0 pb-2">
                                            <list-head-item
                                                id="title"
                                                v-model="headerState"
                                                name="Title"
                                                :sortable="true"
                                            />
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Starts
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Impressions
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Start ratio
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Duration
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Avg viewing time
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Viewed ratio
                                        </div>
                                        <div
                                            class="flex-grow-1 column-title short-text-column"
                                        >
                                            Total watch time
                                        </div>
                                        <div class="flex-grow-1  mr-8" />
                                    </div>
                                </v-col>
                                <loading-bar v-if="loading" />
                                <!-- table content -->
                                <v-row
                                    v-if="!loading"
                                    class="col-md-12 no-gutters pt-0 pb-0 list-container"
                                >
                                    <no-search-results v-if="noSearchResult" />
                                    <v-col v-for="video in videos"
                                           :key="video.id"
                                           cols="12"
                                    >
                                        <list-control-item
                                            :video="video"
                                            :video-statistics="videoStatistics"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <div class="col-md-12">
                                        <x-paginator v-model="pagination" />
                                    </div>
                                </v-row>
                                <!-- end table content -->
                            </div>
                        </fragment>
                    </v-col>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { $http, videoApiUrl } from '@/services/http';
    import qs from 'qs';
    import XPaginator from '@/components/controls/paginator.vue';
    import ListHeadItem from '@/components/primitives/list-head-item.vue';
    import ListControlItem from './list-control-item.vue';
    import LoadingBar from '@/components/controls/loading-bar.vue';
    import NoSearchResults from '@/components/controls/no-search-result.vue';
    import StatisticsRepository from './statistics-repository.js';
    import LibraryFilter from './library-filter';
    import StandaloneStatsDatePicker from '@/components/pages/statistics/standalone-stats-date-picker';
    import { mapActions } from 'vuex';
    import TimeHelper from '@/time-helper';

    export default {
        name: 'Statistics',
        components: {
            StandaloneStatsDatePicker,
            LibraryFilter,
            ListHeadItem,
            XPaginator,
            ListControlItem,
            LoadingBar,
            NoSearchResults,
        },
        data() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);

            return {
                loading: true,
                showCsvProgress: false,
                downloadCsvFileName: 'download.csv',
                disableDownloadButton: true,
                csvContent: '',
                videos: [],
                videoStatistics: {},
                headerState: {
                    sorting: {
                        targetId: 'publish_date',
                        direction: 'desc',
                    },
                    filters: {
                        dateRange: [start, end],
                        libraryIds: [],
                    },
                },
                pagination: {
                    range: 2,
                    currentPage: 1,
                    itemPerPage: 12,
                    total: 0,
                },
            };
        },
        computed: {
            noSearchResult() {
                return !this.loading && this.videos.length === 0;
            },
        },
        methods: {
            ...mapActions('libraries', ['getLibraries']),
            async getPage() {
                this.loading = true;

                const res = await this.fetchTheVideos(this.pagination.currentPage, this.pagination.itemPerPage);

                if(res.data.items.length) {
                    this.videoStatistics = await this.fetchTheStats(res.data.items);
                }

                this.pagination = {
                    ...this.pagination,
                    currentPage: res.data.pagination.current,
                    total: res.data.pagination.total,
                };
                this.videos = res.data.items;
                this.loading = false;
            },
            async fetchTheStats(items) {
                const statisticsRepo = new StatisticsRepository(
                    items,
                    this.headerState.filters.dateRange
                );
                return statisticsRepo.fetchAll();
            },
            async fetchTheVideos(currentPage, pageSize) {
                const toDate = Math.floor(
                    this.headerState.filters.dateRange[1].getTime() / 1000
                );
                return $http.get(videoApiUrl('api/videos-for-statistics'), {
                    params: {
                        filters: {
                            library: this.headerState.filters.libraryIds,
                            date: toDate,
                        },
                        sort: {
                            column: this.headerState.sorting.targetId,
                            direction: this.headerState.sorting.direction,
                        },
                        pagination: {
                            pageSize: pageSize,
                            current: currentPage,
                        },
                    },
                    paramsSerializer: (params) => {
                        return decodeURIComponent(qs.stringify(params));
                    },
                });
            },
            async downloadCsv() {
                if (this.csvContent !== '') {
                    this.showDownload();
                    return;
                }
                var currentPage = 1;
                var heading = ['Id', 'Title', 'Starts', 'Impressions', 'Start ratio', 'Duration', 'Avg viewing time', 'Viewed ratio', 'Total watch time' ];
                var videosCollection = [heading];
                this.showCsvProgress = true;
                await this.fetchCsvDataAndGenerate(videosCollection, currentPage, 100);
                this.showCsvProgress = false;
                this.downloadCsvFileName = await this.downloadFileName();
                this.showDownload();
            },
            async downloadFileName() {
                let ids = this.headerState.filters.libraryIds;
                const res = await $http.get(videoApiUrl('api/libraries-i-can-see'));
                const dateFrom = this.headerState.filters.dateRange[0].toLocaleDateString('nl');
                const dateTo = this.headerState.filters.dateRange[1].toLocaleDateString('nl');
                let libraries = res.data;
                if (ids.length > 0) {
                    ids = JSON.parse(JSON.stringify(ids));
                    libraries = res.data.filter((item) => { return ids.indexOf(item.id) !== -1; });
                }
                let flat = libraries.flatMap((item) => { return item.name; });
                let librariesText = flat.join('-').replaceAll(' ', '_');
                //limit chars because of windows - change-title-of-on-demand-stats-qpd-1022
                let librariesTextLimited = librariesText.substring(0, 215-'statistics-2022-12-22-2022-12-22.csv'.length-1);
                return `statistics-${librariesTextLimited}-${dateFrom}-${dateTo}.csv`;
            },
            showDownload() {
                const blob = new Blob([this.csvContent], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('href', url);
                a.setAttribute('download', this.downloadCsvFileName);
                a.click();
            },
            async fetchCsvDataAndGenerate(videosCollection, currentPage, pageSize) {
                var result = await this.fetchTheVideos(currentPage, pageSize);
                if (result.data.items.length === 0) {
                    var csvContent = '';
                    videosCollection.forEach(function(rowArray){
                        var row = rowArray.join(',');
                        csvContent += row + '\n';
                    });
                    this.csvContent = csvContent;
                    return;
                }
                var stats = await this.fetchTheStats(result.data.items);
                result.data.items.forEach((video) => {
                    var impressions = stats[video.id].impressions;
                    var starts = stats[video.id].starts;
                    var startRatio = (impressions > 0
                        ? Math.round((starts / impressions) * 100)
                        : 0) + '%';
                    var avgViewTime = stats[video.id].avg_view_time;
                    var videoDuration = TimeHelper.fromMsToHis(video.duration);
                    var videoAvgViewTime = TimeHelper.fromMsToHis(avgViewTime ?? 0);
                    var totalWatchTime = TimeHelper.fromMsToHis(stats[video.id].total_watch_time ?? 0);

                    const avgWatchTime = avgViewTime / 1000;
                    const duration = Math.floor(video.duration / 1000);
                    const percent = duration > 0 ? Math.round((avgWatchTime / duration) * 100) : 0;
                    const viewedRatio = `${percent}%`;


                    videosCollection.push([
                        video.id,
                        '"' + video.title + '"',
                        starts,
                        impressions,
                        startRatio,
                        videoDuration,
                        videoAvgViewTime,
                        viewedRatio,
                        totalWatchTime
                    ]);
                });
                await this.fetchCsvDataAndGenerate(videosCollection, currentPage + 1, pageSize);
            }
        },
        watch: {
            'headerState.filters': {
                deep: true,
                handler() {
                    this.disableDownloadButton = this.headerState.filters.libraryIds.length <= 0;
                    this.pagination.currentPage = 1;
                    this.getPage();
                    this.csvContent = '';
                },
            },
            'pagination.currentPage'(value, oldValue) {
                if (value !== oldValue) {
                    this.getPage();
                }
            },
        },
        mounted() {
            this.getPage();
            this.getLibraries();
        },
    };
</script>

<style scoped lang="scss">
.above-else {
  z-index: 999;
}

.column-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13.6px;
}

.stats-title-column{
    flex: 1 1 535px;
}
.download-button {
    background-color: white;
    border-color:rgb(204, 204, 204);
    color: rgb(85, 85, 85)
}
</style>
