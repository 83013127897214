<template>
    <v-content class="interactive-editor-page grey lighten-4">
        <app-bar />
        <left-drawer />
        <keep-alive :include="['uploadPage']">
            <router-view />
        </keep-alive>
    </v-content>
</template>

<script>
    import AppBar from '../controls/app-bar';
    import LeftDrawer from '../controls/left-drawer';

    export default {
        components: {
            AppBar,
            LeftDrawer,
        },
        watch: {
            '$route' () {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    this.$store.commit('layout/closeLeftDrawer');
                }
            }
        }
    };
</script>

<style scoped>
  .left-drawer-overlay {
    background-color: rgba(0,0,0,.6);
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    z-index: 4;
  }
</style>
