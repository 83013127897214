<template>
    <v-app-bar
        clipped-left
        clipped-right
        app
        color="white"
        light
    >
        <v-btn
            icon
            @click.stop="toggleDrawer"
        >
            <v-icon v-if="$vuetify.breakpoint.mdAndDown && drawer || $vuetify.breakpoint.lgAndUp && !drawer">
                menu_open
            </v-icon>
            <v-icon v-else>
                menu
            </v-icon>
        </v-btn>
        <v-toolbar-title
            style="width: 300px"
            class="ml-0 pl-lg-10 mr-8 d-flex"
        >
            <v-img
                src="/images/logo-quadia-horizontal-w145.png"
                class="lighten-4"
                max-width="145"
                max-height="100"
            />
            <div class="ml-2 title-text purple--text text--ligthen-1">
                Platform
            </div>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
            v-if="uploadAllowed"
            color="primary"
            class="mx-2 hidden-sm-and-down white--text"
            :to="{name: 'upload'}"
        >
            Upload files
        </v-btn>
        <v-sheet
            :width="sheet_width"
            class="d-flex align-center"
        >
            <v-spacer />
            <v-btn href="/quadia-platform-manual.pdf"
                   color="primary"
                   target="_blank"
                   text
            >
                Support
            </v-btn>
            <v-divider vertical
                       class="ma-2"
            />
            <user-menu />
        </v-sheet>
    </v-app-bar>
</template>

<script>
    import UserMenu from './user-menu';
    import {toastSuccess} from '../../services/responseErrors';
    import {mapGetters, mapState} from 'vuex';
    import Gate from '../../services/gate';
    import store from '../../store/index';

    export default {
        name: 'AppBar',
        components: {
            UserMenu
        },
        data() {
            return {
                sheet_width: 362,
            };
        },
        computed: {
            ...mapState('layout', {
                drawer: 'leftDrawer',
            }),
            ...mapGetters('auth', ['canUploadEverywhere']),

            uploadAllowed: function(){
                return Gate.allows(store.getters['auth/user'], 'upload') || this.canUploadEverywhere;
            },
        },
        methods: {
            toggleDrawer: function() {
                this.$store.commit('layout/toggleLeft');
            },

            search: function() {
                toastSuccess('Search');
            }
        },
        created() {
            if (!this.$vuetify.breakpoint.smAndDown) {
                let w = this.$vuetify.breakpoint.mdAndDown ? 35 : 25;
                this.sheet_width = this.$vuetify.breakpoint.width * w / 100 + 7;
            }
        }
    };
</script>

<style scoped>
    .v-btn--active:before {
        background-color: transparent !important;
    }
    .title-text{
        font-size: 1.2em;
    }
</style>
