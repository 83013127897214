<template>
    <v-expansion-panel class="elevation-0">
        <v-expansion-panel-header>General</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-simple-table
                dense
                class="property-sheet"
            >
                <template v-slot:default>
                    <tbody>
                        <meta-title-field
                            :video="video"
                            :videos="videos"
                            :multiple-videos="multipleVideos"
                            @input="updateTitle"
                        />
                        <tr v-if="!multipleVideos">
                            <td>Status:</td>
                            <td>{{ video ? visibilityTypeText : "" }}</td>
                        </tr>
                        <tr v-if="!multipleVideos">
                            <td>Video ID:</td>
                            <td>{{ video ? video.id : "" }}</td>
                        </tr>
                        <tr v-if="!multipleVideos && (libraries.length > 1 || canUploadEverywhere)">
                            <td>Library: </td>
                            <td>
                                <v-sheet>
                                    {{ libraryName }}
                                </v-sheet>
                                <copy-video
                                    v-if="canCopy"
                                    :video="video"
                                />
                                <move-video
                                    v-if="canMove"
                                    :video="video"
                                    @moved="updateLibrary"
                                />
                            </td>
                        </tr>
                        <meta-description-field
                            v-if="!multipleVideos && video"
                            :video="video"
                        />
                        <meta-tags-field
                            v-if="video"
                            :video="video"
                        />
                        <tr v-if="!multipleVideos">
                            <td>Upload date:</td>
                            <td>{{ video.publish_date ? simpleDate : 'N/A' }}</td>
                        </tr>
                        <tr v-if="!multipleVideos">
                            <td>Uploaded by:</td>
                            <td>{{ video.metadata.uploadedBy }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import TimeHelper from '../../../time-helper';
    import MetaDescriptionField from './fields/meta-description-field';
    import MetaTagsField from './fields/meta-tags-field';
    import MetaTitleField from './fields/meta-title-field';
    import {mapActions, mapGetters, mapState} from 'vuex';
    import VisibilityHelper from '../../../visibility-helper';
    import Gate from '../../../services/gate';
    import CopyVideo from '@/components/primitives/copy-video';
    import MoveVideo from '@/components/primitives/move-video';

    export default {
        name: 'MetadataGeneral',
        components: {
            MoveVideo,
            CopyVideo,
            MetaTagsField,
            MetaDescriptionField,
            MetaTitleField
        },
        props: ['videos', 'multipleVideos', 'video'],
        data() {
            return {
            };
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('libraries', ['libraries']),
            ...mapGetters('libraries', ['getLibraryCompanyName', 'allowedLibrariesBy']),
            ...mapGetters('auth', ['canUploadEverywhere']),
            simpleDate() {
                return TimeHelper.formatYmdHi(TimeHelper.fromUnixToClientsTz(this.video.publish_date));
            },
            hasSourceLibraries()
            {
                return this.allowedLibrariesBy(this.user.permissions['copy-from-library']).length > 0;
            },
            hasTargetLibrariesForMove()
            {
                return this.allowedLibrariesBy(this.user.permissions['move-to-library'])
                    .filter(library => library.id !== this.video.library).length > 0;
            },
            hasTargetLibrariesForCopy()
            {
                return this.allowedLibrariesBy(this.user.permissions['copy-to-library'])
                    .filter(library => library.id !== this.video.library).length > 0;
            },
            canMove() {
                return Gate.allows(this.user, ['moveVideo']) && this.hasSourceLibraries && this.hasTargetLibrariesForMove;
            },
            canCopy()
            {
                return this.canUploadEverywhere || (Gate.allows(this.user, ['copyVideo']) && this.hasSourceLibraries && this.hasTargetLibrariesForCopy);
            },
            libraryName() {
                if (!this.video) {
                    return '';
                }

                let oldCompany = null;
                let hasMoreCompanies = false;

                this.libraries.forEach(library => {
                    if (!library.company) {
                        return;
                    }

                    if (oldCompany !== library.company.company_name) {
                        if (oldCompany != null) {
                            hasMoreCompanies = true;
                        }
                        oldCompany = library.company.company_name;
                    }
                });

                const library = this.libraries.find(library => library.id === this.video.library);

                if (library) {
                    return (hasMoreCompanies ? this.getLibraryCompanyName(library) + ' / ' : '') + library.name;
                }

                return 'n/a';
            },
            visibilityTypeText() {
                return VisibilityHelper.getOption(this.video.visibility.type);
            }
        },
        methods: {
            ...mapActions('libraries', ['getLibraries']),
            updateTitle(value){
                this.videos[0].title = value;
            },
            updateLibrary(value) {
                this.videos[0].library = value;
            }
        },
        mounted() {
            this.getLibraries();
        }
    };
</script>

<style scoped>

</style>
