import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store/index';

Vue.use(VueRouter);

import MainLayout from './components/layouts/main';
import AuthLayout from './components/layouts/auth';
import ExternalUploadLayout from './components/layouts/external-upload-layout';
import InteractiveEditorLayout from './components/layouts/interactive-editor';
// pages
import Index from './components/pages/index';
import AuthCallback from './components/pages/auth-callback';
import FTP from './components/pages/ftp/ftp';
import Upload from './components/pages/upload';
import ExternalUpload from './components/pages/external-upload';
import Users from './components/pages/users';
import AddEditUser from './components/pages/add-edit-user';
import Companies from './components/pages/companies';
import AddEditCompany from './components/pages/add-edit-company';
import Profile from './components/pages/profile';
import Libraries from './components/pages/libraries';
import AddEditLibrary from './components/pages/add-edit-library';
import Statistics from './components/pages/statistics/statistics';
import StatisticsDetails from './components/pages/statistics/statistics-details';
import NotFound from './components/pages/not-found';
import VimeoAuthorize from './components/pages/social-media/vimeo-authorize';
import YoutubeAuthorize from './components/pages/social-media/youtube-authorize';
import UsageStatsPage from './components/pages/usage-stats';
import InteractiveEditor from './components/pages/interactive-editor';
import { refreshWhenDeprecated } from './services/versioning';
import Gate from './services/gate';
import AddEditPlayerTemplate from './components/pages/add-edit-library-player-template';
import PresentationEditor from './components/pages/presentation-editor';
import { $auth } from './services/auth';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', component: MainLayout, children: [
                {
                    path: '',
                    name: 'index',
                    redirect: { name: 'mediaLibrary' }
                },
                {
                    path: '/media-library',
                    name: 'mediaLibrary',
                    component: Index
                },
                {
                    path: '/ftp',
                    name: 'ftp-upload',
                    component: FTP,
                },
                {
                    path: '/upload',
                    name: 'upload',
                    component: Upload,
                },
                {
                    path: '/users',
                    name: 'users',
                    component: Users,
                },
                {
                    path: '/users/create',
                    name: 'addUser',
                    component: AddEditUser
                },
                {
                    path: '/users/:id',
                    name: 'editUser',
                    component: AddEditUser
                },
                {
                    path: '/companies',
                    name: 'companies',
                    component: Companies
                },
                {
                    path: '/companies/create',
                    name: 'addCompany',
                    component: AddEditCompany
                },
                {
                    path: '/companies/:id',
                    name: 'editCompany',
                    component: AddEditCompany
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile
                },
                {
                    path: '/libraries',
                    name: 'libraries',
                    component: Libraries
                },
                {
                    path: '/libraries/create',
                    name: 'addLibrary',
                    component: AddEditLibrary
                },
                {
                    path: '/libraries/:id',
                    name: 'editLibrary',
                    component: AddEditLibrary
                },
                {
                    path: '/statistics',
                    name: 'statistics',
                    component: Statistics
                },
                {
                    path: '/statistics/:id',
                    name: 'statistics-details-page',
                    component: StatisticsDetails
                },                
                {
                    path: '/vimeo/authorize',
                    name: 'VimeoAuthorize',
                    component: VimeoAuthorize
                },
                {
                    path: '/youtube/authorize',
                    name: 'YoutubeAuthorize',
                    component: YoutubeAuthorize
                },
                {
                    path: '/usage-stats',
                    name: 'usageStatsPage',
                    component: UsageStatsPage
                },
                {
                    path: '/libraries/:libraryId/player-templates/create',
                    name: 'addLibraryPlayerTemplate',
                    component: AddEditPlayerTemplate
                },
                {
                    path: '/libraries/:libraryId/player-templates/:templateId',
                    name: 'editLibraryPlayerTemplate',
                    component: AddEditPlayerTemplate
                },
            ]
        },
        {
            path: '/videos/:id/interactive', component: InteractiveEditorLayout, children: [
                {
                    path: '/',
                    name: 'interactiveEditor',
                    component: InteractiveEditor
                }
            ]
        },
        {
            path: '/videos/:id/presentation', component: InteractiveEditorLayout, children: [
                {
                    path: '/',
                    name: 'presentationEditor',
                    component: PresentationEditor
                }
            ]
        },
        {
            path: '/', component: AuthLayout, children: [
                {
                    path: '/auth',
                    name: 'AuthCallback',
                    component: AuthCallback,
                    meta: {
                        omitRefreshToken: true
                    }
                },
            ]
        },
        {
            path: '/external-upload*', component: ExternalUploadLayout, children: [
                {
                    path: '/',
                    name: 'externalUpload',
                    component: ExternalUpload,
                    meta: {
                    }
                }
            ]
        },
        {
            path: '*', component: NotFound
        }

    ]
});

function accessAllowed(to) {
    const user = store.getters['auth/user'];
    if (to.name === 'ftp-upload') {
        return Gate.allowsFtpAccess(user);
    }
    if(to.name === 'statistics' || to.name === 'mediaLibrary'){
        return Gate.allowsStatisticsOrMediaLibrary(user);
    }

    return Gate.allows(user, to.name);
}

router.beforeEach(async (to, from, next) => {
    if (!store.state.auth.authenticated) {
        if (!to.meta.omitRefreshToken) {
            await $auth.refresh();
        }
    }

    if (to.name === 'upload' && store.getters['auth/canUploadEverywhere']) {
        return next();
    }

    if (accessAllowed(to)) {
        next();
    } else {
        next({
            name: Gate.allows(store.getters['auth/user'], 'profile') ? 'profile' : '404'
        });
    }
});

router.afterEach(() => {
    refreshWhenDeprecated();
});

export default router;
