<template>
    <div class="list-control-item">
        <v-card class="elevation-0 list-mode d-flex py-1">
            <div class="image-column pa-2">
                <thumbnail :item="video" />
            </div>
            <div class="flex-grow-1 title-column pa-0">
                <div class="mx-4">
                    {{ video.title ? video.title : "Title" }} 
                </div>
                <div class="mx-4 mt-1 library-name">
                    {{ libraryName }}
                </div>
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <span class="">{{ starts }}</span>
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <span class="mx-4">{{ impressions }}</span>
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <span class="mx-4">{{ startRatio }}</span>
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <duration-span class="mx-4"
                               :video="video"
                />
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <duration-span class="mx-4"
                               :video="{duration: avgViewTime}"
                />
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <span class="mx-4">{{ viewedRatio }}</span>
            </div>
            <div class="flex-grow-1 short-text-column column-value">
                <duration-span class="mx-4"
                               :video="{duration: totalWatchTime}"
                />
            </div>
            <div class="flex-grow-1 column-value">
                <v-btn
                    icon
                    title="Open detail page"
                    color="primary"
                    :to="{name: 'statistics-details-page', params: {id: video.id}}"
                    target="_blank"
                >
                    <v-icon
                        small
                    >
                        mdi-chart-bar
                    </v-icon>
                </v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>
    import Thumbnail from '@/components/controls/thumbnail.vue';
    import DurationSpan from '@/components/controls/duration-span.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'ListControlItem',
        props: ['video', 'videoStatistics'],
        components: {
            Thumbnail,
            DurationSpan,
        },
        computed: {
            ...mapState('libraries', ['libraries']),
            starts() {
                return this.videoStatistics[this.video.id].starts;
            },
            impressions() {
                return this.videoStatistics[this.video.id].impressions;
            },
            startRatio() {
                return (this.impressions > 0
                    ? Math.round((this.starts / this.impressions) * 100)
                    : 0) + '%';
            },
            avgViewTime() {
                return this.videoStatistics[this.video.id].avg_view_time ?? 0;
            },
            totalWatchTime() {
                return this.videoStatistics[this.video.id].total_watch_time ?? 0;
            },
            viewedRatio() {
                const avgWatchTime = this.avgViewTime / 1000;
                const duration = Math.floor(this.video.duration / 1000);
                const percent = duration > 0 ? Math.round((avgWatchTime / duration) * 100) : 0;
                return `${percent}%`;
            },
            libraryName() {
                if (!this.video) {
                    return '';
                }

                let oldCompany = null;
                let hasMoreCompanies = false;

                this.libraries.forEach(library => {
                    if (!library.company) {
                        return false;
                    }

                    if (oldCompany !== library.company.company_name) {
                        if (oldCompany != null) {
                            hasMoreCompanies = true;
                        }
                        oldCompany = library.company.company_name;
                    }
                });

                const library = this.libraries.find(library => library.id === this.video.library);

                if (library) {
                    return (hasMoreCompanies ? library.company.company_name + ' / ' : '') + library.name;
                }

                return 'n/a';
            },
        },
    };
</script>

<style lang="scss" scoped>
.column-value{
    display: flex;
    justify-content: center;
    align-items: center;
}

.library-name {
  opacity: 0.5;
}

.list-control-item .v-card.list-mode > div {
  height: auto !important;
}

.image-column {
  display: flex;
}
</style>